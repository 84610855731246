<template>
  <div class="panel-chat">
    <div id="chatBar" class="modal-chat-bar">
      <span v-if="!showChatBox" class="text-title">{{ formTitle }}</span>
      <span v-else class="text-title">{{ title }}</span>
      <div class="container-close-btn">
        <font-awesome-icon
          icon="times"
          @click="hideModalChat"
          class="close-btn"
        />
      </div>
    </div>
    <div class="container-layout">
      <ChatForm @setShowChatBox="setShowChatBox" v-if="!showChatBox" />
      <ChatBox v-if="showChatBox" />
    </div>
  </div>
</template>

<script>
import config from "./config.js";
import ChatForm from "./ChatForm.vue";
import ChatBox from "./ChatBox.vue";

export default {
  name: "ChatLayout",
  components: {
    ChatForm,
    ChatBox,
  },
  mounted() {
    if (
      this.$cookies.isKey("next-i18next") &&
      this.$cookies.get("next-i18next") == "en"
    ) {
      this.formTitle = config.preChatForm.formTitleEn;
    } else {
      this.formTitle = config.preChatForm.formTitle;
    }
    document.getElementById("chatBar").style.backgroundColor = config.color;
  },
  data() {
    return {
      title: config.title,
      showChatBox: false,
      formTitle: config.preChatForm.formTitle,
    };
  },
  methods: {
    hideModalChat() {
      this.$emit("setShowModal", false);
    },
    setShowChatBox(show) {
      this.showChatBox = show;
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-chat {
  width: 100%;
  height: 100%;
  box-shadow: 0 0.5rem 1rem rgba(black, 0.15);
  border-radius: 10px;
}
.modal-chat-bar {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px;
  padding: 0.5rem;
}
.text-title {
  color: white;
  font-size: 20px;
  font-family: "DBHeavent";
}
.close-btn {
  color: white;
  font-size: 20px;
  font-family: "DBHeavent";
}
.close-btn:hover {
  cursor: pointer;
}
.container-layout {
  height: auto;
  background: white;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  padding: 0;
}
.container-close-btn {
  top: auto;
  position: absolute;
  right: 15px;
  cursor: pointer;
  display: block;
}
.container-close-btn svg {
  height: 0.82rem;
  width: auto;
  margin-top: 4px;
}
@media (max-width: 767.98px) {
  .panel-chat {
    width: 100%;
    height: 100%;
    border: none;
    border-radius: 0;
  }
  .modal-chat-bar {
    border-radius: 0;
  }
  .container-layout {
    border-bottom-left-radius: 0px;
    border-bottom-right-radius: 0px;
  }
}
</style>
