<template>
  <div class="div-input">
    <div class="display-only" v-if="isDisplay"></div>
    <div :class="['input-custom', { error: isValidate }]">
      <label v-if="textFloat" :class="className">
        {{ textFloat }}
        <span v-if="isRequired" class="require-label">*</span>
      </label>
      <span v-if="textDescription" class="text-des">
        {{ textDescription }}
      </span>
      <input
        :class="['custom-input f-regular']"
        :type="type"
        :placeholder="placeholder"
        :name="name"
        :required="required"
        :value="value"
        :size="size"
        @input="$emit('input', $event.target.value)"
        @change="onDataChange"
        @keypress="$emit('onkeypress', $event)"
        @keyup="$emit('onkeyup', $event.target.value)"
        ref="input"
        :maxlength="maxlength"
        :disabled="isDisplay"
        :id="id"
      />
      <div v-if="buttonAction" class="button-action" @click="buttonAction">
        <font-awesome-icon
          v-if="buttonActionIcon.length"
          :icon="buttonActionIcon"
          :class="[buttonActionClass]"
        />
      </div>
    </div>
    <span v-if="detail" class="text-desc">{{ detail }}</span>
    <div v-if="v && v.$error">
      <span class="text-error" v-if="v.required == false">
        {{
          cookieLang == "th"
            ? "กรุณากรอกข้อมูลให้ครบถ้วน"
            : "This field can’t be empty"
        }}
      </span>
      <span class="text-error" v-else-if="v.minLength == false">{{
        cookieLang == "th"
          ? "กรุณากรอกอย่างน้อย " + v.$params.minLength.min + "อักษร"
          : "Please enter at least " + v.$params.minLength.min + " characters"
      }}</span>
      <span class="text-error" v-else-if="v.validateTotal == false">
        {{
          cookieLang == "th"
            ? "กรุณาระบุจำนวนเงินให้ถูกต้อง"
            : "Please enter amount correctly"
        }}
      </span>
      <span class="text-error" v-else-if="v.email == false">{{
        cookieLang == "th"
          ? "กรุณากรอกอีเมล (เช่น email@example.com)"
          : "Please enter your email address (e.g. email@example.com)"
      }}</span>
      <span class="text-error" v-else-if="v.integer == false">{{
        cookieLang == "th"
          ? "กรุณากรอกตัวเลขเท่านั้น"
          : "Please enter only number"
      }}</span>
      <span class="text-error" v-else-if="v.maxLength == false">{{
        cookieLang == "th"
          ? "กรุณากรอกไม่เกิน " + v.$params.maxLength.max + " อักษร"
          : "Please enter no more than " +
            v.$params.maxLength.max +
            " characters"
      }}</span>
      <span class="text-error" v-else-if="v.decimal == false">{{
        cookieLang == "th"
          ? "กรุณากรอกตัวเลขเท่านั้น"
          : "Please enter only number"
      }}</span>
      <span class="text-error" v-else-if="v.minValue == false">{{
        cookieLang == "th"
          ? "กรุณากรอกตัวเลขเท่านั้น"
          : "Please enter only number"
      }}</span>
      <span class="text-error" v-else-if="v.sameAsPassword == false">{{
        cookieLang == "th" ? "รหัสผ่านไม่ตรงกัน" : "Passwords do not match"
      }}</span>
      <span class="text-error" v-else-if="v.alpha == false">{{
        cookieLang == "th"
          ? "กรอกเฉพาะตัวอักษรเท่านั้น"
          : "Please enter only letters"
      }}</span>
      <span class="text-error" v-else-if="v.password == false">{{
        cookieLang == "th"
          ? "รหัสผ่านจะต้องประกอบด้วยตัวอักษร a-z และ 1-9 ควรมีความยาวไม่ต่ำกว่า 6 ตัวอักษร"
          : "Password must contain letters a-z and 1-9. It should be at least 6 characters"
      }}</span>
    </div>
  </div>
</template>

<script>
import config from "../chat/config";

export default {
  props: {
    textFloat: {
      required: false,
      type: String,
    },
    textDescription: {
      required: false,
      type: String,
    },
    className: {
      required: false,
      type: String,
    },
    placeholder: {
      required: false,
      type: String,
    },
    type: {
      required: true,
      type: String,
    },
    required: {
      required: false,
      type: Boolean,
    },
    detail: {
      required: false,
      type: String,
    },
    size: {
      required: false,
      type: String,
    },
    name: {
      required: false,
      type: String,
    },
    value: {
      required: false,
      type: [String, Number],
    },
    isDisplay: {
      required: false,
      type: Boolean,
    },
    isRequired: {
      required: false,
      type: Boolean,
    },
    isValidate: {
      required: false,
      type: Boolean,
    },
    v: {
      required: false,
      type: Object,
    },
    maxlength: {
      required: false,
      type: [Number, String],
    },
    buttonAction: {
      required: false,
      type: Function,
    },
    buttonActionClass: {
      required: false,
      type: String,
    },
    buttonActionIcon: {
      required: false,
      type: Array,
    },
    id: {
      required: false,
      type: String,
    },
  },
  mounted() {
    this.cookieLang = this.$cookies.isKey("next-i18next")
      ? this.$cookies.get("next-i18next")
      : "th";
  },
  methods: {
    onDataChange(event) {
      this.$emit("onDataChange", event.target.value);
    },
    focus() {
      this.$refs.input.focus();
    },
  },
  data() {
    return {
      primaryColor: config.color,
      cookieLang: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.div-input {
  margin-bottom: 10px;
  position: relative;
}
.input-custom {
  padding: 0px;
}
.input-custom > input:disabled {
  background-color: white;
}
.input-custom > label {
  color: #595a5c; // label form
  font-size: 20px;
  font-family: "DBHeavent";
}
.input-custom > input {
  border: 1px solid #99999959; // border input
  color: #000;
  background-color: white;
  border-radius: 6px;
  padding: 5px 10px;
  margin-top: 3px;
}
.input-custom > input[size="lg"] {
  height: 45px;
}
.input-custom > input:focus {
  outline: 0px;
  box-shadow: 1px 1px 3px #999999;
}
.input-custom.error > input {
  border-color: red !important;
}
::-webkit-input-placeholder {
  /* Edge */
  font-family: "DBHeavent";
}
:-ms-input-placeholder {
  /* Internet Explorer */
  font-family: "DBHeavent";
}
::placeholder {
  font-family: "DBHeavent";
}
.custom-input {
  display: block;
  border: none;
  width: 100%;
}
.text-desc {
  color: rgba(22, 39, 74, 0.4);
  font-size: 20px;
  font-family: "DBHeavent";
}
.display-only {
  position: absolute;
  z-index: 5;
  width: 100%;
  height: 100%;
  border-radius: 10px;
}
.text-error {
  color: #d3545d;
  font-size: 20px;
}

.button-action {
  position: absolute;
  right: 10px;
  top: 2.3rem;
  z-index: 6;
}
.text-des {
  color: #a0a0a0;
  font-size: 20px;
}
.button-action:hover {
  cursor: pointer !important;
}
.require-label {
  color: #ff0000;
}
label {
  margin-bottom: 0px;
}
</style>
