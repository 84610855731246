<template>
  <div class="panel-chat-footer " v-if="!showChat">
    <button
      id="btnModalChat"
      class="btn-chat"
      @click="setShowModalChat(!showChat)"
      v-bind:style="[{ backgroundColor: primaryColor }]"
    >
      <font-awesome-icon icon="comments" class="icon-chat" />
      <span class="text-chat">{{ title }}</span>
    </button>
  </div>
</template>

<script>
import config from "./chat/config.js";

export default {
  name: "ChatModal",
  props: {
    isShowModalChat: {
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      title: config.titleButton,
      primaryColor: config.color,
      showChat: this.isShowModalChat,
    };
  },
  methods: {
    setShowModalChat(show) {
      this.showChat = show;
      this.$emit("setShowModal", show);
    },
  },
};
</script>

<style lang="scss" scoped>
.panel-chat-footer {
  position: fixed;
  bottom: 1rem;
  right: 1rem;
  z-index: 100;
}
.btn-chat {
  height: 40px;
  width: 100px;
  padding: 0.4rem;
  border: 0px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
}
.btn-chat:hover {
  cursor: pointer;
}
.icon-chat {
  font-size: 25px;
  color: white;
}
.text-chat {
  font-size: 20px;
  margin-left: 5px;
  color: white;
  font-family: "DBHeavent";
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89,90,92, 0.25);
  outline: none;
}
</style>
