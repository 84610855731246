<template>
  <div>
    <VueSlickCarousel v-bind="slickOptions">
      <div
        v-for="(content, i) in messageItem.messageResponse.content.imageList
          .imageUrl"
        :key="i"
        class="msg_wrap"
      >
        <div class="wrap-product-img">
          <div
            class="product-img"
            v-bind:style="{
              backgroundImage: `url(&quot;${content.ImageUrl}&quot;)`,
            }"
          ></div>
        </div>
        <div class="container-text">
          <span
            v-html="messageItem.messageResponse.content.content.text"
            class="msg-card-title"
            >{{ messageItem.messageResponse.content.content.text }}
          </span>
        </div>
     
      </div>
      <template #prevArrow="arrowOption">
        <div class="custom-arrow">
          {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
        </div>
      </template>
      <template #nextArrow="arrowOption">
        <div class="custom-arrow">
          {{ arrowOption.currentSlide }}/{{ arrowOption.slideCount }}
        </div>
      </template>
    </VueSlickCarousel>
  </div>
</template>

<script>
import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
// optional style for arrows & dots
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import config from "../config.js";

export default {
  name: "ChatBoxImageCarousel",
  components: {
    VueSlickCarousel,
  },
  props: {
    messageItem: {
      required: false,
      type: Object,
    },
  },
  data() {
    return {
      themeColor: config.color,
      slickOptions: {
        arrows: true,
        infinite: false,
        slidesToShow: 1,
        dots: false,
        initialSlide: 0,
        autoplay: false,
      },
    };
  },
};
</script>

<style lang="scss" scoped>
.msg_wrap {
  overflow-wrap: break-word;
  padding-right: 5px;
  font-size: 20px;
}
.wrap-product-img {
  width: 100%;
  height: auto;
}
.product-img {
  width: 100%;
  padding-bottom: 100.5%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #fff;
  border-radius: 10px;
}
.msg-card-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  display: flex;
  justify-content: center;
  font-size: 1.3rem;
  color: #3A3937
}
.msg-card-sub-title {
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  color: #a0a0a0;
  display: flex;
  justify-content: center;
}
.slick-prev:before,
.slick-next:before {
  color: rgba(22, 39, 74, 0.4);
  font-size: 25px;
}
.slick-next {
  margin-right: 5px;
}
.slick-prev.slick-disabled:before,
.slick-next.slick-disabled:before {
  opacity: 0.25;
}
.container-text {
  margin-top: 8px;
}
.container-btn {
  padding-top: 8px;
  display: flex;
  flex-flow: column;
  margin-top: auto;
}
</style>
