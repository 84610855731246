<template>
  <div class="container-frame">
    <div>
      <div class="display-line container-text-title-top container-item">
        <div
          class="img-icon-title-display"
          v-bind:style="{
            backgroundImage:
              'url(' +
              messageItem.messageResponse.content.location.IconTitle +
              ')',
          }"
        ></div>
        <span
          v-html="messageItem.messageResponse.content.location.Title"
          class="msg-card-title title-black"
          >{{ messageItem.messageResponse.content.location.Title }}</span
        >
      </div>

      <hr />

      <div
        class="container-item container-location"
        v-bind:style="{ borderColor: primaryColor }"
      >
        <div
          v-for="(location, j) in messageItem.messageResponse.content.location
            .Branch"
          :key="j"
          class="item-location"
          @click="
            () => {
              sendMessagePostback(location.BranchUrl, 2);
            }
          "
        >
          <div class="row-item">
            <div class="display-line item-location-name">
              <div class="icon-section">
                <div
                  class="img-icon-branch-display"
                  v-bind:style="{
                    backgroundImage: 'url(' + location.IconBranch + ')',
                  }"
                ></div>
              </div>
              <div class="info-section">
                <span v-html="location.BranchName" class="info">
                  {{ location.BranchName }}</span
                >
              </div>
            </div>
            <span v-html="location.Distance" class="item-distance"
              >{{ location.Distance }}
            </span>
            <font-awesome-icon
              icon="chevron-right"
              class="icon-go"
              v-bind:style="{ color: primaryColor }"
            />
          </div>
          <div class="lower-row display-line">
            <div class="icon-clock-section">
              <div
                class="img-icon-time-display"
                v-bind:style="{
                  backgroundImage: 'url(' + location.IconOpenHour + ')',
                }"
              ></div>
            </div>
            <div class="info-clock-section">
              <span v-html="location.OpenHour" class="item-time info">
                {{ location.OpenHour }}</span
              >
            </div>
          </div>
          <div
            class="row-item lower-row time-round"
            v-bind:style="{ color: primaryColor }"
          >
            <span v-html="location.CutOffTime" class="">{{
              location.CutOffTime
            }}</span>
          </div>
        </div>

        <div class="container-btn">
          <button
            type="button"
            class="list-btn"
            v-bind:style="{ backgroundColor: primaryColor }"
            @click="
              () => {
                handleClickButton(
                  messageItem.messageResponse.content.location.Button.Action,
                  messageItem.messageResponse.content.location.Button.Label,
                  messageItem.messageResponse.content.location.Button.Data
                );
              }
            "
          >
            {{ messageItem.messageResponse.content.location.Button.Label }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import config from "../config.js";

export default {
  name: "ChatBoxLocation",
  props: {
    messageItem: {
      required: false,
      type: Object,
    },
  },
  mounted() {
    // open hour new line when > 1 case
    // ex. จ.-ศ. 10:30-20:30, ส.-อา. 10:00-20:30
    for (let val of this.messageItem.messageResponse.content.location.Branch) {
      if (val.OpenHour.includes(", ")) {
        val.OpenHour = val.OpenHour.replace(", ", "\n");
      }
    }
  },
  data() {
    return {
      primaryColor: config.color,
    };
  },
  methods: {
    handleClickButton(btnAction, btnLabel, btnData) {
      if (btnAction == 1) {
        this.$emit("send-message", { text: btnData }, 1);
      } else if (btnAction == 2) {
        this.handleOpenTab(btnData);
      } else if (btnAction == 3) {
        // post back
        this.$emit("send-message", { text: btnData }, 2, btnLabel);
      } else if (btnAction == 6) {
        this.openMap();
      }
    },
    handleOpenTab(url) {
      window.open(url, "_blank");
    },
    sendMessagePostback(labelButton, mesType) {
      var dataSend = { text: labelButton };
      this.$emit("send-message", dataSend, mesType);
    },
    openMap() {
      this.$emit("setShowMap", true);
    },
  },
};
</script>

<style lang="scss" scoped>
.container-frame {
  padding-top: 10px;
  padding-bottom: 10px;
}
.container-item {
  padding-left: 12px;
  padding-right: 12px;
}
hr {
  border-width: 1px 0px 0px 0px;
  border-style: solid;
  border-color: #e0e0e0;
  margin-top: 10px;
  margin-bottom: 10px;
}
.container-text-title-top {
  margin-top: 0px;
}
.display-line {
  display: inline-flex;
}
.msg-card-title {
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 0px;
  line-height: normal;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  font-size: 1.3rem;
}
.title-black {
  color: #3a3937;
  justify-content: flex-start;
}
.container-location {
  border-radius: 5px;
  border: black 1px solid;
  margin-left: 12px;
  margin-right: 12px;
  padding-top: 10px;
}
.container-btn {
  padding-top: 0px;
  display: flex;
  flex-flow: column;
}
.list-btn {
  border-radius: 0.5rem;
  margin-bottom: 5px;
  color: white;
  border: 0px;
  font-size: 20px;
  padding: 0.4rem 0px 0.4rem 0px;
  font-family: "DBHeavent";
}
.list-btn:hover {
  cursor: pointer;
}
.row-item {
  display: flex;
  justify-content: space-between;
}
.lower-row {
  font-size: 18px;
  padding-left: 23px;
}
.time-round {
  padding-left: 23px;
}
.item-location {
  margin-bottom: 10px;
  line-height: 1;
}
.item-location:hover {
  cursor: pointer;
}
.item-location-name {
  width: 95%;
  display: flex;
  font-size: 20px;
  align-items: flex-start;
}
.item-time {
  margin-left: 3px;
}
.item-distance {
  overflow-wrap: break-word;
  width: 40%;
  display: flex;
  justify-content: flex-end;
  font-size: 18px;
  color: #595a5c;
}
.icon-go {
  margin-left: 5px;
  padding-bottom: 2px;
  font-size: 15px;
  height: 1rem !important;
  width: auto !important;
}
.img-icon-title-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 40px;
  height: 40px;
}
.img-icon-branch-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 18px;
  height: 25px;
}
.img-icon-time-display {
  background-size: 100%;
  background-position: center;
  background-repeat: no-repeat;
  width: 12px;
  height: 20px;
}
.info {
  margin-left: 3px;
  word-wrap: break-word;
  white-space: pre-wrap;
  word-break: break-word;
  color: #595a5c;
  display: block;
  width: 100%;
  font-size: 18px;
  padding-top: 1px;
}
.icon-section {
  width: 15%;
}
.icon-clock-section {
  width: auto;
}
button:focus {
  box-shadow: 0 0 0 0.1rem rgba(89, 90, 92, 0.25);
  outline: none;
}

@media (max-width: 767.98px) {
  .icon-section {
    width: auto;
  }
}
</style>
