var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.messageItem.messageResponse.content.responseType != 17)?_c('div',[(
      (_vm.messageItem.messageResponse.content.responseType == 1 ||
        (_vm.messageItem.messageResponse.content.responseType == 2 &&
          _vm.messageItem.senderId == _vm.userId)) &&
        _vm.messageItem.messageResponse.content.content.text
    )?_c('div',{staticClass:"container-all-item"},[(_vm.messageItem.senderId != _vm.userId && _vm.agentImg)?_c('div',{},[_c('img',{staticClass:"img-agent",attrs:{"src":("" + _vm.agentImg)}})]):_vm._e(),_c('div',{class:[
        _vm.messageItem.senderId == _vm.userId ? 'my-msg' : 'res-msg all-width' ]},[_c('div',{staticClass:"msg-item"},[(_vm.messageItem.senderId != _vm.userId && _vm.agentName)?_c('div',{staticClass:"agent-name non-left"},[_c('span',[_vm._v(_vm._s(_vm.agentName))])]):_vm._e(),_c('div',[_c('p',{staticClass:"item item-text border-res-msg",domProps:{"innerHTML":_vm._s(
              _vm.replaceURLs(
                _vm.messageItem.messageResponse.content.content.text
              ).replace(/(?:\r\n|\r|\n)/g, '<br />')
            )}},[_vm._v(" {{ replaceURLs(messageItem.messageResponse.content.content.text).replace(/(?:\\r\\n|\\r|\\n)/g, '"),_c('br'),_vm._v("') }} ")])])]),(_vm.messageItem.senderId == _vm.userId && _vm.userImg)?_c('div',[_c('img',{staticClass:"img-user",attrs:{"src":("" + _vm.userImg)}})]):_vm._e()])]):_vm._e(),(
      _vm.messageItem.messageResponse.content.responseType == 6 &&
        _vm.messageItem.senderId == _vm.userId
    )?_c('div',{staticClass:"container-all-item"},[_c('div',{staticClass:"my-msg"},[_c('div',{staticClass:"msg-item item-location",on:{"click":function($event){return _vm.handleOpenTab(
            'https://www.google.com/maps/search/?api=1&query=' +
              _vm.messageItem.messageResponse.content.location.latitude +
              ',' +
              _vm.messageItem.messageResponse.content.location.longitude
          )}}},[_c('div',[_c('div',{staticClass:"msg-map"},[_c('img',{staticClass:"img-map",attrs:{"src":_vm.urlImgMap,"height":"","width":"100%"}})]),_c('p',{staticClass:"item mes-address",style:([{ backgroundColor: _vm.secondaryColor }])},[_vm._v(" "+_vm._s(_vm.messageItem.messageResponse.content.location.address)+" ")])])]),(_vm.messageItem.senderId == _vm.userId && _vm.userImg)?_c('div',[_c('img',{staticClass:"img-user",attrs:{"src":("" + _vm.userImg)}})]):_vm._e()])]):_vm._e(),(
      _vm.messageItem.senderId == _vm.userId &&
        (_vm.messageItem.messageResponse.content.responseType == 5 ||
          _vm.messageItem.messageResponse.content.responseType == 4)
    )?_c('div',{staticClass:"container-all-item"},[(_vm.messageItem.senderId != _vm.userId && _vm.agentImg)?_c('div',[_c('img',{staticClass:"img-agent",attrs:{"src":("" + _vm.agentImg)}})]):_vm._e(),_c('div',{staticClass:"my-msg"},[_c('div',{staticClass:"msg-item"},[(_vm.messageItem.senderId != _vm.userId && _vm.agentName)?_c('div',{staticClass:"agent-name non-left"},[_c('span',[_vm._v(_vm._s(_vm.agentName))])]):_vm._e(),_vm._l((_vm.messageItem.messageResponse.content.imageList
            .imageUrl),function(item,i){return _c('div',{key:i},[_c('p',{staticClass:"item item-file border-res-msg",on:{"click":function($event){return _vm.handleOpenTab(item.link)}}},[_c('font-awesome-icon',{staticClass:"icon-file-download",attrs:{"icon":"file-download"}}),_vm._v(" "+_vm._s(item.name)+" ")],1)])})],2),(_vm.messageItem.senderId == _vm.userId && _vm.userImg)?_c('div',[_c('img',{staticClass:"img-user",attrs:{"src":("" + _vm.userImg)}})]):_vm._e()])]):_vm._e(),(
      _vm.messageItem.senderId != _vm.userId &&
        _vm.messageItem.messageResponse.content.responseType == 8
    )?_c('div',{staticClass:"container-all-item"},[(_vm.messageItem.senderId != _vm.userId && _vm.agentImg)?_c('div',[_c('img',{staticClass:"img-agent",attrs:{"src":("" + _vm.agentImg)}})]):_vm._e(),_c('div',{staticClass:"res-msg all-width"},[_c('div',{staticClass:"msg-item"},[(_vm.messageItem.senderId != _vm.userId && _vm.agentName)?_c('div',{staticClass:"agent-name non-left"},[_c('span',[_vm._v(_vm._s(_vm.agentName))])]):_vm._e(),_vm._l((_vm.messageItem.messageResponse.content.imageList
            .imageUrl),function(item,i){return _c('div',{key:i},[_c('p',{staticClass:"item item-file border-res-msg",on:{"click":function($event){return _vm.handleOpenTab(item.url)}}},[_c('font-awesome-icon',{staticClass:"icon-file-download",attrs:{"icon":"file-download"}}),_vm._v(" "+_vm._s(item.name)+" ")],1)])})],2),(_vm.messageItem.senderId == _vm.userId && _vm.userImg)?_c('div',[_c('img',{staticClass:"img-user",attrs:{"src":("" + _vm.userImg)}})]):_vm._e()])]):_vm._e(),(
      (_vm.messageItem.messageResponse.content.responseType == 3 &&
        _vm.messageItem.senderId == _vm.userId) ||
        (_vm.messageItem.senderId != _vm.userId &&
          _vm.messageItem.messageResponse.content.responseType == 4)
    )?_c('div',{class:[_vm.messageItem.senderId == _vm.userId ? 'my-img' : 'res-img all-width']},[_c('div',{},[(_vm.messageItem.senderId != _vm.userId && _vm.agentImg)?_c('div',[_c('img',{staticClass:"img-agent",attrs:{"src":("" + _vm.agentImg)}})]):_vm._e()]),_c('div',{staticClass:"all-width"},[(_vm.messageItem.senderId != _vm.userId && _vm.agentName)?_c('div',{staticClass:"agent-name"},[_c('span',[_vm._v(_vm._s(_vm.agentName))])]):_vm._e(),_vm._l((_vm.messageItem.messageResponse.content.imageList
          .imageUrl),function(item,i){return _c('div',{key:i,staticClass:"img_upload"},[_c('div',{staticClass:"img_upload-display",style:({
            backgroundImage: ("url(\"" + item + "\")"),
          }),on:{"click":function($event){return _vm.handleOpenTab(item)}}})])})],2),(_vm.messageItem.senderId == _vm.userId && _vm.userImg)?_c('div',{},[_c('div',[_c('img',{staticClass:"img-user",attrs:{"src":("" + _vm.userImg)}})])]):_vm._e()]):_vm._e(),(
      !_vm.messageItem.senderId &&
        (_vm.messageItem.messageResponse.content.responseType == 2 ||
          _vm.messageItem.messageResponse.content.responseType == 3 ||
          _vm.messageItem.messageResponse.content.responseType == 5 ||
          _vm.messageItem.messageResponse.content.responseType == 6 ||
          _vm.messageItem.messageResponse.content.responseType == 7 ||
          _vm.messageItem.messageResponse.content.responseType == 9 ||
          _vm.messageItem.messageResponse.content.responseType == 10 ||
          _vm.messageItem.messageResponse.content.responseType == 11 ||
          _vm.messageItem.messageResponse.content.responseType == 18 ||
          _vm.messageItem.messageResponse.content.responseType == 19)
    )?_c('div',{staticClass:"container-all-item"},[(_vm.agentImg)?_c('div',[_c('img',{staticClass:"img-agent",attrs:{"src":("" + _vm.agentImg)}})]):_vm._e(),_c('div',{staticClass:"all-width"},[(_vm.agentName)?_c('div',{staticClass:"agent-name"},[_c('span',[_vm._v(_vm._s(_vm.agentName))])]):_vm._e(),_c('div',{staticClass:"msg_cotainer_send extent-width border-res-msg",class:[
          _vm.messageItem.messageResponse.content.responseType != 7
            ? 'bg-white'
            : 'non-border',
          _vm.messageItem.messageResponse.content.responseType == 9
            ? 'extent-width-location'
            : 'extent-width' ]},[_c('ChatBoxFlex',{attrs:{"messageItem":_vm.messageItem},on:{"setShowMap":_vm.setShowMap,"send-message":_vm.sendMessage}})],1)])]):_vm._e(),(
      !(
        _vm.messageItem.messageResponse.content.responseType == 1 &&
        !_vm.messageItem.messageResponse.content.content.text
      )
    )?_c('div',{class:[
      _vm.messageItem.senderId == _vm.userId ? 'date-user' : 'date-agent',
      _vm.userImg ? 'date-user-img-user' : '' ]},[_c('ChatDateTime',{attrs:{"date":_vm.messageItem.createdTime,"senderId":_vm.messageItem.senderId}})],1):_vm._e()]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }