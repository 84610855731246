<template>
  <span v-if="senderId == userId" class="date-time-user date-time">
    {{ getHumanDate() }}
  </span>
  <span v-else class="date-time">
    {{ getHumanDate() }}
  </span>
</template>

<script>
export default {
  name: "ChatDateTime",
  props: {
    date: {
      required: true,
      type: String,
    },
    senderId: {
      required: false,
      type: String,
    },
  },
  mounted() {
    this.cookieLang = this.$cookies.isKey("next-i18next")
      ? this.$cookies.get("next-i18next")
      : "th";
  },
  methods: {
    getHumanDate: function() {
      if (
        this.$moment(this.date).format("DD") ===
        this.$moment(new Date()).format("DD")
      ) {
        if (this.cookieLang == "en") {
          return (
            "Today at " +
            this.$moment(this.date)
              .tz("Asia/Bangkok")
              .format("HH:mm")
          );
        } else {
          return (
            "วันนี้ " +
            this.$moment(this.date)
              .tz("Asia/Bangkok")
              .format("HH:mm")
          );
        }
      } else if (
        this.$moment(this.date).isSame(this.$moment().subtract(1, "day"), "day")
      ) {
        if (this.cookieLang == "en") {
          return (
            "Yesterday at " +
            this.$moment(this.date)
              .tz("Asia/Bangkok")
              .format("HH:mm")
          );
        } else {
           return (
            "เมื่อวาน " +
            this.$moment(this.date)
              .tz("Asia/Bangkok")
              .format("HH:mm")
          );
        }
      } else if (
        this.$moment(this.date).format("YYYY") ===
        this.$moment(new Date()).format("YYYY")
      ) {
        return this.$moment(this.date)
          .tz("Asia/Bangkok")
          .format("DD MMM, HH:mm");
      } else {
        return this.$moment(this.date)
          .tz("Asia/Bangkok")
          .format("DD MMM YYYY");
      }
    },
  },
  data() {
    return {
      userId: this.$cookies.get("d-user-info").userId,
      cookieLang: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.date-time {
  font-size: 20px;
  margin-top: 3px;
  color: #999999;
  margin-bottom: 0px;
  font-family: "DBHeavent";
}
.date-time-user {
  display: flex;
  justify-content: flex-end;
}
</style>
